.footer-container {
    background-color: #242424;
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-icons {
    width: 240px;
    justify-self: center;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
  }