video{
    object-fit: cover;
    width: 100%;
    height:100%;
    position: relative;
    z-index: -1;
    margin-top: -20%;

}

.hero-container{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    /* background-color: #dee2e6; */
    object-fit: contain;
    

}

.hero-container > h1{
    color: #000000;
    font-size: 100px;
    margin-top: -150px;
}

.hero-container > p{
    margin-top: -14%;
    color: #000000;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    animation: fadeInAnimation ease 5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

.hero-btns{
    margin-top: 32px;
    animation: fadeInAnimation ease 5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.hero-btns .btn{
    margin: 6px;
}

@media  screen and (max-witdh:960px) {
    .hero-container > h1{
        font-size: 70px;
        margin-top: -150px;
    }
}

@media  screen and (max-width:768px) {
    .hero-container > h1{
        font-size: 50px;
        margin-top: -100px;
    }

    .hero-container > p{
        font-size: 30px;
    }
    
    .btn-mobile{
        display: block;
        text-decoration: none;
    }

    .btn{
        width: 100%;
    }
}